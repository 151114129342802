import React from "react";
import "./Nedostupno.css"; // Make sure to import the CSS file

const WebshopUnavailable = () => {
    return (
        <div className="centered-container color padding" style={{ paddingTop: "10rem" }}>
            <div className="outer-div">
                <h1 className="font-tiskani veliki flex-za-veliki" style={{ padding: "2rem", textAlign: "center" }}>
                    Ups... trenutno radimo na ažuriranju stranice, družimo se vrlo brzo, posjeti nas opet
                </h1>

                <h1 className="font-tiskani veliki flex-za-veliki" style={{ padding: "2rem", textAlign: "center" }}>
                    Do tada nam slobodno piši na WhatsApp na broj telefona
                    <span className="no-break">+385 91 766 25 99</span>
                </h1>
            </div>
        </div>
    );
};

export default WebshopUnavailable;
