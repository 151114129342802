import React, {useEffect} from 'react';
import './PaymentDetails.css';
import PropTypes from "prop-types";
import {getCartProducts, getTotal} from "../../../reducers";
import {
    cancelOrderUser,
    checkout,
    confirmOrder,
    getAllProducts,

} from "../../../actions";
import {connect} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {MDBCol, MDBContainer, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import ProductOnPayment from "./ProductOnPayment"; // Import CSS file for styling
import "../../../fonts/fonts.css"

const PaymentDetails = ({ products, total, getAllProducts, cancelOrderUser, confirmOrder }) => {

    const location = useLocation()
    const navigate = useNavigate()
    const { order } = location.state;

    console.log("order in paymetn", order)

    // const cartTotal = 2
    // const shippingPrice = 3

    useEffect(() => {
        // Dispatch the getAllProducts action when the component mounts
        getAllProducts();
    }, [getAllProducts]);

// Check only for products and total for loading condition
//     if (!products || !total) {
//         return <div>Loading...</div>; // Render loading indicator if products or total are not available
//     }

    // const [showColumns, setShowColumns] = useState(true);

// useEffect hook to listen to window resize events and update the state accordingly
    useEffect(() => {
        const handleResize = () => {
            // setShowColumns(window.innerWidth >= 800);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount

    const cartTotal = parseFloat(order.totalAmountCart)
    const shippingPrice = parseFloat(order.shippingMethodPrice)

    const onConfirmOrder = () => {
        confirmOrder(order.orderId)
            .then(msg => {
                // After successful checkout, navigate to the payment page with the required data
                console.log(msg)
                // Navigate to the payment page with the delivery data
                navigate('/');

            })
            .catch(error => {
                // Handle errors
                console.error('Checkout error:', error);
            });
    }

    const onCancelOrder = () => {
        cancelOrderUser(order.orderId)
            .then(msg => {
                // After successful checkout, navigate to the payment page with the required data
                console.log(msg)
                // Navigate to the payment page with the delivery data
                navigate('/cart');

            })
            .catch(error => {
                // Handle errors
                console.error('Checkout error:', error);
            });
    }


    // console.log("state", location.state)
    // console.log(delivery)
    // console.log(deliveryPrice)
    // console.log(products)
    // console.log(total)

    const hasProducts = products.length > 0
    const nodes = hasProducts ? (
        products.map(product =>
            <ProductOnPayment
                productName={product.productName}
                brandName={product.brandName}
                image={product.image}
                price={product.price}
                specialPrice={product.specialPrice}
                quantity={product.quantity}
                productId={product.productId}
                key={product.productId}
            />
        )
    ) : (
        <em>Please add some products to cart.</em>
    )

    return (

        <div style={{paddingTop: "10rem"}}>
            {order.paymentMethod === "Transakcijski račun" &&
                <div className="naplata-container">
                    <h2 className="font-tiskani">Naplata</h2>
                    <p className="font-tiskani">Vaša narudžba je zaprimljena. Kako biste potvrdili svoju narudžbu,
                        molimo Vas uplatu na IBAN prema danim podacima za plaćanje. Kad uplatite iznos molimo Vas da
                        potvrdite svoju narudžbu pritiskom na gumb na dnu stranice. Potvrda o narudžbi će biti poslana
                        na Vašu email adresu. Hvala Vam na narudžbi !</p>
                </div>
            }
            {order.paymentMethod === "Plaćanje poduzećem" &&
                <div className="naplata-container">
                    <h2 className="font-tiskani">Naplata</h2>
                    <p className="font-tiskani">Vaša narudžba je zaprimljena. Nakon pregledanih podataka, molimo Vas da potvrdite svoju narudžbu pritiskom na gumb na dnu stranice. Naplata će se izvršiti pri dostavi narudžbe kurirskom službom. Potvrda o narudžbi će biti poslana
                        na Vašu email adresu. Hvala Vam na narudžbi !</p>
                </div>
            }

                <div className="payment-details-container"> {/* Apply container class */}
                <h4 className="font-tiskani">Detalji narudžbe</h4>
                <table className="payment-details-table"> {/* Apply table class */}
                    <tbody>
                    <tr>
                        <td className="font-tiskani"><strong>Broj narudžbe:</strong></td>
                        <td className="font-tiskani">
                            {order.orderId}
                        </td>
                    </tr>
                    <tr>
                        <td className="font-tiskani"><strong>Iznos:</strong></td>
                        <td className="font-tiskani">€{(cartTotal+shippingPrice).toFixed(2)}</td>
                        {/*<td className="font-tiskani">{(parseInt(total)}€</td>*/}
                            {/*+parseInt(getPrice(selectedOption))).toFixed(2)*/}


                    </tr>
                    <tr>
                        <td className="font-tiskani"><strong>Datum:</strong></td>
                        <td className="font-tiskani">{order.orderDate}</td>
                    </tr>
                    <tr>
                        <td className="font-tiskani"><strong>Email adresa:</strong></td>
                        <td className="font-tiskani">{order.email}</td>
                    </tr>
                    <tr>
                        <td className="font-tiskani"><strong>Način plaćanja</strong></td>
                        <td className="font-tiskani">{order.paymentMethod}</td>
                    </tr>
                    </tbody>
                </table>
            </div>


            {order.paymentMethod === "Transakcijski račun" &&
                <div className="payment-details-container"> {/* Apply container class */}
                    <h4 className="font-tiskani">Podaci za plaćanje</h4>
                    <table className="payment-details-table"> {/* Apply table class */}
                        <tbody>
                        <tr>
                            <td className="font-tiskani"><strong>Primatelj:</strong></td>
                            <td className="font-tiskani">
                                Nina Hršak Beauty Boutique d.o.o
                                <div>Ulica Vjekoslava Klaića 12</div>
                                <div>10000 Zagreb</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-tiskani"><strong>Iznos:</strong></td>
                            <td className="font-tiskani">€{(cartTotal + shippingPrice).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="font-tiskani"><strong>IBAN: HR2923400091111309404</strong></td>
                            <td className="font-tiskani"></td>
                        </tr>
                        <tr>
                            <td className="font-tiskani"><strong>Poziv na broj:</strong></td>
                            <td className="font-tiskani">{order.orderId}</td>
                        </tr>
                        <tr>
                            <td className="font-tiskani"><strong>Opis plaćanja:</strong></td>
                            <td className="font-tiskani">Plaćanje po narudžbi {order.orderId}</td>
                        </tr>
                        </tbody>
                    </table>
                    {/*<p className="font-tiskani">Hvala unaprijed</p>*/}
                </div>
            }

            <div>
                <section className="h-100 h-custom padding">
                    <MDBContainer className="container-cart py-5 h-100 color-white" style={{justifyContent:"center"}}>
                        <MDBRow className="justify-content-center align-items-center h-100">
                            <MDBCol>
                                <MDBTable responsive>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope="col" className="h5 font-tiskani-mali">
                                                Košarica
                                            </th>
                                            {/*<th scope="col" className="h6 font-tiskani">Format</th>*/}
                                            {<th scope="col" style={{textAlign: "center"}}
                                                 className="h6 font-tiskani-mali">Količina</th>}
                                            {<th scope="col" style={{textAlign: "center"}}
                                                 className="h6 font-tiskani-mali">Cijena</th>}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {nodes}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCol>
                            {/*<div style={{float: "right", textAlign: "right"}}>*/}
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <p className="mb-2 font-tiskani-mali">Ukupno: </p>
                                <p className="mb-2 font-tiskani-mali padding-price">{total}€</p>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between" }} className="">
                                <p className="mb-2 font-tiskani-mali">Dostava: </p>
                                <p className="mb-2 font-tiskani-mali padding-price">{shippingPrice.toFixed(2)}€</p>
                            </div>
                            <hr style={{ width: '100%', color: 'lightgray', margin: '10px 0' }} className="" />
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <p className="mb-2 font-tiskani-mali">Sveukupno: </p>
                                <p className="mb-2 font-tiskani-mali padding-price">{(cartTotal+shippingPrice).toFixed(2)}€</p>
                            </div>
                        </MDBRow>
                    </MDBContainer>
                </section>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                <button id="button-pay" className="font-tiskani-mali" onClick={onCancelOrder} style={{ margin: "2rem 1rem" }}>
                    Odustani
                </button>
                <button id="button-pay" className="font-tiskani-mali" onClick={onConfirmOrder} style={{ margin: "2rem 1rem" }}>
                    Potvrdi narudžbu
                </button>
            </div>
            </div>
        </div>
    );
};

PaymentDetails.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        productId: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        brandName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        specialPrice: PropTypes.number.isRequired,
        discount: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired
    })),
    total: PropTypes.string,
    getAllProducts: PropTypes.func.isRequired, // Add getAllProducts to propTypes
    checkout: PropTypes.func.isRequired,
    cancelOrderUser: PropTypes.func.isRequired,
    confirmOrder: PropTypes.func.isRequired,
    // removeAllFromCart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    products: getCartProducts(state),
    total: getTotal(state)
});

const mapDispatchToProps = {
    getAllProducts, // Add getAllProducts to mapDispatchToProps
    checkout,
    cancelOrderUser,
    confirmOrder
    // addToCart,
    // removeFromCart,
    // removeAllFromCart,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentDetails);
